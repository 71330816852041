
  import axios, { AxiosError } from 'axios';
  import { IUserData } from "@/store/UsersStore";
  import { defineComponent, ref, onMounted, onBeforeMount, watch, handleError } from 'vue';
  import { useRouter } from "vue-router";
  import { campHandleCPF } from '@/composables/DataValidation';
  import { useUsersStore } from "@/store/UsersStore";
  import useAlert from "@/composables/Alert";
  import {
    CampHeader,
    CampEmptyListFeedbackV2,
    CampTable,
    CampTableTd,
    CampTableTh,
    CampFooterPaginationControl,
    CampBtnGenerateExcel
  } from '@/components';
  import { useLoaderStore } from "@/store/LoaderStore";
  import { useStoreStore } from '@/store/StoreStore';
  import CampModalDelRegister from '@/components/CampModalDelRegister.vue';
  import CampModalRecoverRegister from '@/components/CampModalRecoverRegister.vue';
  import { campGoToTopByScrollMode } from '@/composables/Helpers';
  import ModalCompetitorsInfo from '@/views/Developer/components/ModalCompetitorsInfo.vue';
  import { useCompanyStore } from '@/store/CompanyStore';
  import { useFilterMainStore } from '@/store/FilterMainStore';
  import { ICompanyData } from '@/store/CompaniesStore';
  
  export interface ICompetitorData {
    id: number;
    name: string;
    cpf: string;
    email: string;
    tel: string;
    url_media?: string | null;
    role?: number;
    deleted_at: string | null;
    cpf_competitor: string;
    AccessCode: number
    last_page: number
    id_company: number
    latestAccessCode: number | null
    store: number
    latestAccess: string| null
    use_version: string | null
    card_number: string | null
    card_pin: string | null
    id_role: number
    storeName: string | null
    companyName: string | null
    created_at: string
    financial_data: string
    updated_at: string | null 
    url_photo: string | null
    birth_date: string | null

  }

  interface IPagination {
    currentPage: number;
    totalPages: number;
    perPage: number;
    totalItems: number;
  }
  
  export default defineComponent({
    name: "CompetitorList",
    components: {
      CampHeader,
      CampEmptyListFeedbackV2,
      CampTable,
      CampTableTd,
      CampTableTh,
      CampModalDelRegister,
      CampModalRecoverRegister,
      CampFooterPaginationControl,
      ModalCompetitorsInfo,
      CampBtnGenerateExcel
    },
    setup() {
      const storeStore = useStoreStore()
      const router = useRouter();
      const relationProps = ref(false)
      const companyStore = useCompanyStore()
      const filterMainStore = useFilterMainStore()
      const modalInfoLog = ref<{ competitor: ICompetitorData | null, toggle: boolean }>({
        competitor: null,
        toggle: true
      })
      const pageID = ref(1)
      const pageTotal = ref(1)
      const queryObject = ref<{
        isLoading: boolean,
        noError: boolean,
        data:  null | ICompetitorData[]
        pagination?: IPagination
      }>({
        isLoading: false,
        noError: false,
        data: null
      })
      const printCompetitors = ref<ICompetitorData[]>([]);
      const keywordSearch = ref("")
      const relationData = ref<ICompetitorData | null>(null)
      const loaderStore = useLoaderStore();
      const isLoading = ref(false)
      const usersStore = useUsersStore()
      const { showTimeAlert } = useAlert()
      const activeStatus = ref(true)
      const tableItem = ref<ICompetitorData | null>(null)
      const toggleModalDelRegister = ref(false)
      const selectedCompetitor = ref<ICompetitorData | null>(null)
      const showModal = ref(false);
      const companyList = ref<any[]>([])
      const storeList = ref<any[]>([])
      const selectedCompanies = ref<string[]>([])
      const selectedStores = ref<string[]>([])
      const filteredCompetitors = ref<ICompetitorData[]>([]);
      const searchCPF = ref<string | null>(null);



      const loadCompaniesAndStores = async () => {
        try {
          isLoading.value = true;
          const [companiesResponse, storesResponse] = await Promise.all([
            axios.get('/api/getAllCompanies'),
            axios.get('/api/getAllStores')
          ]);
          companyList.value = companiesResponse.data.data
          storeList.value = storesResponse.data.data
        } catch (error) {
          if (error instanceof AxiosError) {
            showTimeAlert(error.response?.data.message, "error")
          } else {
            showTimeAlert("Algo deu errado!", "error")
          }
        } finally {
          isLoading.value = false;
        }
      }

      const fetchCompetitors = async () => {
        try {
          isLoading.value = true;

          const storeId = Array.isArray(selectedStores.value) ? selectedStores.value.map(Number) : []
          const companyId = Array.isArray(selectedCompanies.value) ? selectedCompanies.value.map(Number) : []

          const response = await axios.get('/api/getCompetitorsForDevelopers', {
            params: {
              page: pageID.value,
              idCompany: companyId,
              idStore: storeId,
              cpf: searchCPF.value,
            }
          });
          pageTotal.value = response.data.data.last_page
          printCompetitors.value = response.data.data.data
          usersStore.setCompanies(printCompetitors.value)
          queryObject.value.data = response.data.data.data
          filterCompetitors()
        } catch (error) {
          if (error instanceof AxiosError) {
            showTimeAlert(error.response?.data.message, "error")
          } else {
            showTimeAlert("Algo deu errado!", "error")
          }
        } finally {
          loaderStore.close()
          isLoading.value = false
        }
      }

      const filterCompetitors = () => {
        const keyword = keywordSearch.value.toLowerCase()
        filteredCompetitors.value = printCompetitors.value.filter((competitor) => {
          const matchesKeyword = 
            competitor.name.toLowerCase().includes(keyword) ||
            competitor.email.toLowerCase().includes(keyword) ||
            competitor.cpf.toLowerCase().includes(keyword)

          const matchesCompany = !selectedCompanies.value || selectedCompanies.value.length === 0 || selectedCompanies.value.includes(competitor.companyName as string)

          const matchesStore = !selectedStores.value || selectedStores.value.length === 0 || selectedStores.value.includes(competitor.storeName as string)

          const matchesCPF = !searchCPF.value || competitor.cpf.includes(searchCPF.value)

          return matchesKeyword && matchesCompany && matchesStore && matchesCPF
        })
      }

  
      // Functions
      function funcDetailRelationData(data) {
        relationProps.value = !relationProps.value
        relationData.value = data
      }
  
      function handleFilterObjectsByKeyword(): boolean {
        if(!queryObject.value.data)
          return true
        printCompetitors.value = queryObject.value.data.filter((item) => {
          for (const key in item) {
            if (key !== 'checked') {
              const propValue = item[key];
              if (typeof propValue === 'string' && propValue.toLowerCase().includes(keywordSearch.value.toLowerCase())) {
                return true;
              }
              if (typeof propValue === 'number' && keywordSearch!.toString() === propValue.toString()) {
                return true;
              }
            }
          }
          return false;
        });
        return false
      }
  
      function handleDataLocalStorage() {
        const ss = JSON.parse(localStorage.getItem("ss")!)
        if(ss) {
          storeStore.setId(ss.i)
          storeStore.setFantasyName(ss.n)
        }
      }

      const handleModalInfoLog = (el: ICompetitorData) => {
        modalInfoLog.value.toggle = !modalInfoLog.value.toggle
        modalInfoLog.value.competitor = { ...el }
      }

      function handleInitFilterModal() {
        if(!companyStore.getId) {
          filterMainStore.hideModal()
          filterMainStore.showModal()
        }
      }

      async function handlePaginationRendering(ID: number) {
        loaderStore.open()
        pageID.value = ID
        await fetchCompetitors()
        campGoToTopByScrollMode(false)
      }

  
      // Life Cycles
      onBeforeMount(() => handleDataLocalStorage())
  
      onMounted( async () =>(handleInitFilterModal(), loadCompaniesAndStores(), filterCompetitors(),fetchCompetitors()
      ))

      watch(() => companyStore.id, () => fetchCompetitors(), {deep: true})

      watch(() => { companyStore.company, storeStore.store }, () => fetchCompetitors(), { deep: true })

      watch(() => storeStore.getId, () =>isLoading.value = true)
  
      watch(() => storeStore.store, () => fetchCompetitors())
  
      watch(() => keywordSearch.value, () => handleFilterObjectsByKeyword())

      watch(() => { companyStore.getId, storeStore.getId }, () => isLoading.value = true, {deep: true})

      watch([selectedCompanies, selectedStores], filterCompetitors)

      watch([selectedCompanies, selectedStores, searchCPF], async () => {
        pageID.value = 1
      }, { deep:true})


      return {
        router,
        isLoading,
        printCompetitors,
        queryObject,
        keywordSearch,
        campHandleCPF,
        activeStatus,
        tableItem,
        toggleModalDelRegister,
        relationData,
        funcDetailRelationData,
        relationProps,
        handleModalInfoLog,
        modalInfoLog,
        handlePaginationRendering,
        pageID,
        pageTotal,
        selectedCompetitor,
        showModal,
        selectedCompanies,
        selectedStores,
        filterCompetitors,
        companyList,
        storeList,
        fetchCompetitors,
        searchCPF
      }
    }
  })
  