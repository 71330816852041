import { defineStore } from "pinia";
export interface IUserData {
  id: number,
  name: string,
  cpf: string,
  email: string,
  tel: string,
  url_media?: string | null,
  role?: number,
  deleted_at: null | string
  option?:string,
  role_description?: string,
  role_name?: string,
}

export const useUsersStore = defineStore("UserStore", {
  state: () => {
    return {
      user: null as any
    }
  },
  actions: {
    setCompanies(data: IUserData[] | null) {
      this.user = data
    }
  }
});
