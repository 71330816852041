import { Modal } from "bootstrap";
import { ScrollTopComponent, defaultScrollTopOptions } from "@/assets/ts/components";

export const campHandleOpenModal = id => {
  const auxElement = document.querySelector(`#${id}`);
  const auxModal = new Modal(auxElement);
  auxModal.show();
}

export function campHandleCamelCaseToTitleCase(str: string) {
  return str
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .replace(/\b[a-z]/g, function(letter) {
      return letter.toUpperCase();
    });
}

export function campGoToTopByScrollMode(scrolling = true) {
  if(scrolling) {
    ScrollTopComponent.goTop()
    return scrolling
  }

  const speedBackup = defaultScrollTopOptions.speed
  defaultScrollTopOptions.speed = 0
  ScrollTopComponent.goTop()
  defaultScrollTopOptions.speed = speedBackup
  return scrolling
}
