
import { PropType, defineComponent, ref, watch } from "vue";
import { Modal } from "bootstrap";
import moment from "moment";
import type { ICompetitorData } from "@/views/Developer/CompetitorsLogList.vue";
import imgDefault from '@/assets/layout/default-noimgProduct2.png'


export default defineComponent({
  name: "ModalCompetitorsInfo",
  props: {
    toggle: {
      type: Boolean,
      default: false
    },
    competitor: {
      type: Object as PropType<ICompetitorData | null> ,
      required: true
    }
  },
  setup(props, { emit }) {
    // Variables
    let auxModal
    const header = ref(`Informações adicionais de Vendedores`)
    const roleNames = {
    6: 'Moderador',
    7: 'Vendedor',
    8: 'Consultor',
    }

    // Functions
    const openModal = (id: string) => {
      const auxElement = document.querySelector(`#${id}`);
      auxModal = new Modal(auxElement);
      auxModal.show();
      header.value = `Informações adicionais de Vendedores`
    }

    function getRoleName(id) {
    return roleNames[id] || "N/A"
    }

    // Life Cycles
    watch(() => props.toggle, () => {
      openModal("ModalCompetitorsInfo")
    })

    return {
      header,
      moment,
      getRoleName,
      imgDefault,
    }
  }
})


